import React from 'react';

import RegisterCrumb from '../../components/contentRegisterCrumb';
import RegisterSectionPearl from '../../components/contentRegisterPearl';
import RegisterPearlCrumb from '../../components/contentRegisterPearlCrumb';

const RegisterPearl = () => {
    return (
        <div className="body">
            <RegisterPearlCrumb  />
            <RegisterSectionPearl />
        </div>
    );
};

export default RegisterPearl;
