import React from 'react';
import backgroundImage from '../assets/images/bg_sec_malaysiacup2023_banner.jpg';

const RegisterMalaysiaCupCrumb = () => {
    return (
        <section className="mb-0 page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-6"
            style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="container">
                <div className="row mt-3">
                    <div className="col-md-12 align-self-start order-1">
                        <ul className="breadcrumb breadcrumb-light d-block text-start">
                            <li><a href="https://juniorx.my">Welcome</a></li>
                            <li className="active">Register</li>
                        </ul>
                    </div>
                    <div className="col-md-12 align-self-start p-static order-2 text-start">
                        <h1 className="text-9 font-weight-bold">Register Malaysia Cup 2023</h1>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RegisterMalaysiaCupCrumb;
