import { useState, useEffect } from 'react';


const TournamentListFetch = () => {
    const [tournament, setData] = useState([]);
    const [isLoadTournament, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        setIsLoading(true);
        try {

            await new Promise(resolve => setTimeout(resolve, 300));
            const response = await fetch('https://agilemas.my/api/juniorx/tournament');
            const json = await response.json();
            setData(json);
            setIsLoading(false);
        } catch (error) {
            setError(error);
            // alert('There is an error!');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const refetch = () => {
        setIsLoading(false);
        fetchData();
    }
    return { tournament, isLoadTournament, error, refetch };
}

export default TournamentListFetch;
