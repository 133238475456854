import React from 'react';

import RegisterMalaysiaCup from '../../components/contentRegisterMalaysiaCup';
import RegisterMalaysiaCupCrumb from '../../components/contentRegisterMalaysiaCupCrumb';

const RegisterPearl = () => {
    return (
        <div className="body">
            <RegisterMalaysiaCupCrumb  />
            <RegisterMalaysiaCup />
        </div>
    );
};

export default RegisterPearl;
