import React from 'react';

import footerBackgroundImage from '../assets/images/bg_foottop.jpg';
import AgileMASIcon from '../assets/images/logo-juniorx.png';

const Footer = () => {
  return (
    <footer id="footer" className="mt-0 section-background border-0 m-0 p-0" style={{ background: `url(${footerBackgroundImage}) #00ff00`, backgroundPosition: "top center", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}>

      <div className="container d-flex align-items-center py-5">

        <div className="row pt-5">

          <div className="col-lg-2 col-6 mb-5 mb-lg-0">
            <img className="img-fluid" src={AgileMASIcon} alt="JuniorX" />
          </div>

          <div class="col-lg-5 col-6 mb-5 mb-lg-0">
            <p class="mb-1 text-1 font-weight-bold line-height-1 text-color-dark">Address</p>
            <p class="mb-1 line-height-2 text-color-dark">Kompleks Sukan ISN,
              Stadium Jalan Raja Muda Abdul Aziz,
              Kampung Bharu,
              50300 Kuala Lumpur, Malaysia., Selangor, MALAYSIA</p>
          </div>

          <div className="col-lg-5 text-end">
            <div className="d-flex align-items-center justify-content-center justify-content-lg-end">
              <span className="text-4 font-weight-bold text-color-dark">JuniorX</span>
              <ul className="footer-social-icons social-icons social-icons-clean social-icons-icon-dark ms-3">
                <li className="social-icons-facebook"><a href="http://www.facebook.com/juniorxmalaysia" target="_blank" title="Facebook"><i className="fab fa-facebook-f text-2"></i></a></li>
                <li className="social-icons-instagram"><a href="http://www.instagram.com/juniorxmalaysia" target="_blank" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                <li className="social-icons-twitter"><a href="http://www.tiktok.com/@juniorxmalaysia" target="_blank" title="TikTok"><i className="fab fa-tiktok text-2"></i></a></li>
              </ul>
            </div>

            <div className="d-flex align-items-center justify-content-center justify-content-lg-end footer-copyright bg-primary text-color-dark">
              <nav id="sub-menu">
                <ul>
                  <li><i className="fas fa-angle-right"></i><a href="https://juniorx.my/faq_page.html" className="ms-1 text-decoration-none text-color-dark"> FAQ's</a></li>
                  <li><i className="fas fa-angle-right"></i><a href="https://juniorx.my/privacy_policy.html" className="ms-1 text-decoration-none text-color-dark"> Privacy Policy</a></li>
                  <li><i className="fas fa-angle-right"></i><a href="https://juniorx.my/contact_us.html" className="ms-1 text-decoration-none text-color-dark"> Contact Us</a></li>
                </ul>
              </nav>
            </div>
          </div>

        </div>
      </div>

      <div className="footer-copyright footer-copyright-style-2">
        <div className="container py-2">
          <div className="row py-4">
            <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-md-start mb-2 mb-lg-0">
              <p className="text-color-light">©Copyright 2023 - Juniorxsports Sdn Bhd (1521932­W) - All Rights Reserved</p>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-md-end mb-4 mb-lg-0">
              <p><i className="far fa-envelope text-color-primary top-1 p-relative"></i>
                <a href="mailto:admin[@]juniorx.my" className="opacity-7 ps-1 text-color-light">admin[@]juniorx.my</a>
              </p>
            </div>
          </div>
        </div>
      </div>

    </footer>
  );
};

export default Footer;
