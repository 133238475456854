import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';

import AgileMASIcon from '../assets/images/logo_agilemas_black.png';


const RegisterMalaysiaCup = () => {
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [genderData, setGenderData] = useState([]);

    const [states, setState] = useState([]);
    const [ageCheckout, setAgeCheckout] = useState([]);
    const [ageFieldValue, setAgeFieldValue] = useState({});
    const [selectGender, setSelectGender] = useState(false);
    const [fieldName, setFieldName] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);

    const [showProceed, setShowProceed] = useState(false);
    const [formData, setFormData] = useState({
        team: '',
        name: '',
        email: '',
        phoneNo: '',
        event: '45',
        states: '',
        emailValid: false, // Initially assume email is valid
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_URL_PATH + 'malaysiacup/list'); // Replace with your API URL
            setData(response.data.events);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name == 'gender') {
            console.log(value);
            setSelectGender(false);
            if (value == "Male") {
                const maleData = data.filter(item => item.gender === "Male");
                const sortedMaleData = maleData.slice().sort((a, b) => {
                    return a.age.name.localeCompare(b.age.name, undefined, { numeric: true, sensitivity: 'base' });
                });

                setGenderData(sortedMaleData);
            }
            if (value == "Female") {
                const femaleData = data.filter(item => item.gender === "Female");
                const sortedFemaleData = femaleData.slice().sort((a, b) => {
                    return a.age.name.localeCompare(b.age.name, undefined, { numeric: true, sensitivity: 'base' });
                });

                setGenderData(sortedFemaleData);

            }
            setSelectGender(true);
        }
        if (name === 'email') {
            const isValid = validateEmail(value);
            setIsValidEmail(isValid);
            setFormData({
                ...formData,
                email: value,
                emailValid: isValid,
            });
        }
        if (name.startsWith('U')) {
            const ageFieldName = name;
            if (!isNaN(value)) {
                setAgeFieldValue((prevAgeFieldValue) => ({
                    ...prevAgeFieldValue,
                    [ageFieldName]: value
                }));
                setFieldName(ageFieldName);
            } else {
                setFieldName('');
            }
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    const handleProceed = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (!form.checkValidity()) {
            setValidated(true);
            return;
        }

        const ageCheckout = Object.entries(ageFieldValue)
            .filter(([name, total]) => parseInt(total) !== 0 && total !== '')
            .map(([name, total]) => ({
                name,
                total: parseInt(total)
            }));

        if (ageCheckout.length === 0) {
            setValidated(true);
            return;
        }

        if (isValidEmail == false) {
            return;
        }

        setAgeCheckout(ageCheckout)
        setShow(false);
        setShowProceed(true);
    };

    const handleBack = () => {
        setAgeFieldValue({});
        setFieldName('');
        setAgeCheckout([]);
        setShow(true);
        setShowProceed(false);
    };

    const handlePayment = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_URL_PATH + 'malaysiacup/payment', {
                ageCheckout: ageCheckout,
                formData: formData
            });
            const paymentUrl = response.data;
            window.location.href = paymentUrl;
        } catch (error) {
            console.error('Error making payment:', error);
        }
    }

    return (
        <section className="section section-background border-0 m-0 p-0">

            <div className="bg-sec-reg-3">
                <div className="container d-flex align-items-center" style={{ minHeight: '70vh' }}>
                    <div className="row justify-content-center">
                        <div className="col-lg-6 py-4 text-center">
                            <h2 className="text-color-white font-weight-extra-bold text-center text-sm-9 line-height-1 mb-0 py-2" >
                                Register Now!
                            </h2>
                            <p className="text-color-dark font-weight-light text-4 text-center line-height-3 mb-4">
                                Ready to take your football journey to the next level? Secure Your Spot in the Premier Youth 7A Side Football Tournament!"
                            </p>
                            <div>
                                <Button variant="primary" className="btn btn-primary btn-modern font-weight-bold text-2 py-3 btn-px-4" onClick={handleShow}>
                                    Register Now
                                </Button>
                                <Modal
                                    size="md"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Malaysia Cup Registration</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form noValidate validated={validated} onSubmit={handleProceed}>
                                            <Form.Group className="mb-3" controlId="formGroupTeam">
                                                <Form.Label>Team Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Team Name"
                                                    name="team"
                                                    value={formData.team}
                                                    onChange={handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid team name.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formGroupName">
                                                <Form.Label>Coach Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid coach name.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="6" xs='6' controlId="formGroupEmail">
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="email"
                                                        placeholder="Enter email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />

                                                    {isValidEmail == false ?
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid email address.
                                                        </Form.Control.Feedback>
                                                        : <Form.Control.Feedback type="invalid">
                                                            {!formData.emailValid && "Please provide a valid email address."}
                                                        </Form.Control.Feedback>
                                                    }
                                                </Form.Group>

                                                <Form.Group as={Col} md="6" xs='6' controlId="validationCustom02" >
                                                    <Form.Label>Phone No</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="Phone Number"
                                                        name="phoneNo"
                                                        value={formData.phoneNo}
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid phone number.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="6" xs='12' controlId="formGroupName">
                                                    <Form.Label>Event</Form.Label>
                                                    <Form.Select aria-label="Event Name" name="event" disabled>
                                                        <option value="42">Malaysia Cup 2023 </option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select a event.
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6" xs='12' controlId="formGroupName">
                                                    <Form.Label>Gender</Form.Label>
                                                    <Form.Select onChange={handleChange} aria-label="Event Name" name="gender" >
                                                        <option value="">Choose </option>
                                                        <option value="Male">Male </option>
                                                        <option value="Female">Female </option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select a gender.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>


                                            {
                                                selectGender ? <>
                                                    <Alert variant="warning">
                                                        Please insert atleast 1 age group.
                                                    </Alert>
                                                    <Row className="mb-3">
                                                        <Col className="mb-3" md='6' xs='6'> Age Category </Col>
                                                        <Col className="mb-3" md='6' xs='6'>Total Team </Col>
                                                        {/* <Col className="mb-3" md='4' xs='4'>League Slots </Col> */}
                                                        {genderData.map((data) => (
                                                            <>
                                                                <Col className="mt-2" md='6' xs='6'>{data.age.label}</Col>
                                                                <Col md='6' xs='6'>
                                                                    <Form.Group className="mb-3" controlId="formGroupAge">
                                                                        <Form.Control
                                                                            required
                                                                            type="number"
                                                                            name={data.age.name}
                                                                            onChange={handleChange}
                                                                            disabled={data.count_team === 0}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                {/* <Col className="mt-2" md='4' xs='4'>
                                                                    {data.count_team == 0 ? <Badge bg="danger">Full Slots</Badge> : <Badge bg="success">{data.count_team} Slot Left</Badge>}

                                                                </Col> */}
                                                            </>
                                                        ))}
                                                    </Row>
                                                </>
                                                    : ""
                                            }


                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={handleProceed}>
                                            Proceed  <i className="fas fa-arrow-right ms-2"></i>
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal
                                    size="md"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={showProceed} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Proceed Checkout</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form noValidate validated={validated} onSubmit={handlePayment}>
                                            <Form.Group className="mb-3" controlId="formGroupTeam">
                                                <Form.Label>Team Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Team Name"
                                                    name="team"
                                                    value={formData.team}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid name.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formGroupName">
                                                <Form.Label>Coach Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid name.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="6" xs='6' controlId="formGroupEmail">
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid email address.
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6" xs='6' controlId="validationCustom02">
                                                    <Form.Label>Phone No</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Phone Number"
                                                        name="phoneNo"
                                                        value={formData.phoneNo}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid phone No.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="6" xs='12' controlId="formGroupName">
                                                    <Form.Label>Event</Form.Label>
                                                    <Form.Select aria-label="Event Name" name="event" disabled>
                                                        <option value="43">Malaysia Cup 2023 </option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select a event.
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6" xs='12' controlId="formGroupName">
                                                    <Form.Label>Gender</Form.Label>
                                                    <Form.Select aria-label="Event Name" name="event" disabled>
                                                        <option value={formData.gender}>{formData.gender}</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select a event.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Table bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Age Group Participation</th>
                                                        <th>Total</th>
                                                        <th>Price(RM)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ageCheckout.map((age) => (
                                                        <tr key={age.name}>
                                                            <td>Malaysia Cup 2023 - {age.name}</td>
                                                            <td>{age.total}</td>
                                                            <td>{(age.total * 650).toFixed(2)}</td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td colSpan={2}>Processing Fee</td>
                                                        <td>2.50</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Total</td>
                                                        <td>
                                                            {(
                                                                ageCheckout.reduce((total, age) =>
                                                                    total + ( age.total * 650 ),
                                                                    0) + 2.5
                                                            ).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleBack}>
                                            Back  <i className="fas fa-arrow-left ms-2"></i>
                                        </Button>
                                        <Button variant="primary" onClick={handlePayment}>
                                            Checkout  <i className="fas fa-arrow-right ms-2"></i>
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>

                            <div className="pe-1 text-2 my-4 text-color-dark">
                                powered by <img className="img-fluid ps-2" alt="AgileMAS Icon Dark" width="130" src={AgileMASIcon} /></div>

                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default RegisterMalaysiaCup;
