import React, { useState, useEffect } from 'react';
import './Style.css';
import './Theme.css';
import './ThemeElement.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Register from './pages/Register/Register';
import RegisterPearl from './pages/RegisterPearl/RegisterPearl';
import RegisterMalaysiaCup from './pages/RegisterMalaysiaCup/RegisterMalaysiaCup';

import Modal from 'react-bootstrap/Modal';

export default function App() {
    const [paymentFail, setFail] = useState(false);
    const [paymentSuccess, setSuccess] = useState(false);
    const [pearlCup, setPearlCup] = useState('');
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const param = url.pathname.split('/')[1]; 
        setPearlCup(param);

        const paymentStatus = params.get('payment');
        if (paymentStatus === 'success') {
            setSuccess(true)
        }
        if (paymentStatus === 'fail') {
            setFail(true)
        }
    }, []);

    const handleCloseModal = () => {
            window.location.href = '/';
    };

    return (
        <div className="body">
            <Modal
                size="md"
                centered
                show={paymentSuccess}
                onHide={handleCloseModal}
            >
                <Modal.Header style={{ backgroundColor: '#d1e7de', borderColor: '#d1e7de' }} closeButton>
                    <Modal.Title>Success Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#d1e7de', borderColor: '#d1e7de' }} >
                    We will contact you as soon as possible! Hang on tight!</Modal.Body>
                <Modal.Footer style={{ backgroundColor: '#d1e7de', borderColor: '#d1e7de' }}>
                </Modal.Footer>
            </Modal>

            <Modal
                size="md"
                centered
                show={paymentFail}
                onHide={handleCloseModal}
            >
                <Modal.Header style={{ backgroundColor: '#f9d7da', borderColor: '#f9d7da' }} closeButton>
                    <Modal.Title>Payment Fail</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#f9d7da', borderColor: '#f9d7da' }} >
                    Please contact us for more info. Do try again.</Modal.Body>
                <Modal.Footer style={{ backgroundColor: '#f9d7da', borderColor: '#f9d7da' }}>
                </Modal.Footer>
            </Modal>
            <Header />

            <Router>
                <Switch>
                    <Route exact path="/" component={Register} />
                    <Redirect to="/" />
                </Switch>
            </Router>

            <Footer />
        </div>
    );
} 