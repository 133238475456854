import React from 'react';

import RegisterSection from '../../components/contentRegister';
import RegisterCrumb from '../../components/contentRegisterCrumb';


const Register = () => {
    return (
        <div className="body">
            <RegisterCrumb  />
            <RegisterSection />
        </div>
    );
};

export default Register;
