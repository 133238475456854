import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import LogoJunior from '../assets/images/logo-juniorx.png'
import Partner from '../assets/images/nav_bg-partners.jpg'
import District from '../assets/images/nav_bg-districts.jpg'
import Cup from '../assets/images/nav_bg-cup.jpg'
import League from '../assets/images/nav_bg-league.jpg'
import Calendar from '../assets/images/nav_bg-calendar.jpg'
import News from '../assets/images/nav_bg-news.jpg'
import Stats from '../assets/images/nav_bg-stats.jpg'

const Header = () => {
  return (
    <header
      id="header"
      style={{
        top: 0,
        left: 0,
        right: 0,
        zIndex: 999,
      }}
      className="header-transparent header-transparent-dark-bottom-border header-transparent-dark-bottom-border-1 header-effect-shrink"
    >
      <div className="header-body border-top-0 bg-dark box-shadow-none">
        <div className="header-container container">
          <Router>
            <div className="header-row">
              <div className="header-column">
                <div className="header-row">
                  <div className="header-logo">

                    <a href="https://juniorx.my">
                      <img
                        alt="JuniorX"
                        width="209"
                        height="80"
                        src={LogoJunior}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="header-column justify-content-end">
                <div className="header-row">
                  <div className="header-nav header-nav-links header-nav-dropdowns-dark header-nav-light-text order-2 order-lg-1">
                    <div className="header-nav-main header-nav-main-mobile-dark header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-effect-2 header-nav-main-sub-effect-1">
                      {/* Nav Menu */}
                      <nav className="collapse">

                        <ul className="nav nav-pills" id="mainNav">

                          <li><a className="dropdown-item" href="https://juniorx.my">Welcome</a></li>

                          <li className="dropdown dropdown-mega">
                            <a className="dropdown-item dropdown-toggle" href="#">
                              About <i className="fas fa-arrow-down ms-2"></i>
                            </a>
                            <ul className="dropdown-menu">

                              <li>
                                <div className="dropdown-mega-content">
                                  <div className="row">

                                    <div className="col-lg-3">
                                      <span className="dropdown-mega-sub-title">Join Us Now!</span>
                                      <span className="pt-2 text-color-light font-weight-bold">Open for Registration</span>
                                      <p className="text-1 line-height-2 text-color-light">Register for LigaJuniorX tournaments and unlock opportunities to
                                        showcase your skills and compete against the best young talents in Malaysia.</p>
                                      <div className="btn btn-primary" onclick="location.href='https://register.juniorx.my/';">Register Now</div>
                                    </div>

                                    <div className="col-lg-3">
                                      <div onclick="location.href='https://juniorx.my/about_partners.html';">
                                        <span className="dropdown-mega-sub-title">League Partners</span>
                                        <img className="img-fluid w-100"
                                          alt="Partner Logo"
                                          src={Partner} />
                                      </div>
                                    </div>

                                    <div className="col-lg-3">
                                      <div onclick="location.href='https://juniorx.my/about_districts.html';">
                                        <span className="dropdown-mega-sub-title">Districts</span>
                                        <img className="img-fluid w-100"
                                          alt="Districts Logo" src={District} />
                                      </div>
                                    </div>

                                    <div className="col-lg-3">
                                      <span className="dropdown-mega-sub-title">About</span>
                                      <ul className="dropdown-mega-sub-nav">
                                        <li><a className="dropdown-item" href="https://juniorx.my/about.html">About JuniorX</a></li>
                                        <li><a className="dropdown-item" href="https://juniorx.my/about_partners.html">League Partners</a></li>
                                        <li><a className="dropdown-item" href="https://juniorx.my/about_districts.html">Districts <span className="tip tip-dark p-relative bottom-2">New!</span></a></li>
                                      </ul>
                                    </div>

                                  </div>
                                </div>
                              </li>

                            </ul>
                          </li>

                          <li className="dropdown dropdown-mega">
                            <a className="dropdown-item dropdown-toggle" href="#">
                              Competitions <i className="fas fa-arrow-down ms-2"></i>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <div className="dropdown-mega-content">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <div onclick="location.href='https://juniorx.my/comp_league.html';">
                                        <span className="dropdown-mega-sub-title">League Cup</span>
                                        <img className="img-fluid w-100" alt="Cup Logo" src={Cup} />
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div onclick="location.href='https://juniorx.my/comp_tournament.html';">
                                        <span className="dropdown-mega-sub-title">Tournaments</span>
                                        <img className="img-fluid w-100" alt="Tournament Logo" src={League} />
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div onclick="location.href='https://juniorx.my/comp_calendar.html';">
                                        <span className="dropdown-mega-sub-title">JRX Calendar</span>
                                        <img className="img-fluid w-100" alt="Calendar Logo" src={Calendar} />
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="dropdown-mega-sub-title">Competitions</span>
                                      <ul className="dropdown-mega-sub-nav">
                                        <li><a className="dropdown-item" href="https://juniorx.my/comp_league.html">League Cup</a></li>
                                        <li><a className="dropdown-item" href="https://juniorx.my/comp_tournament.html">Tournaments</a></li>
                                        <li><a className="dropdown-item" href="https://juniorx.my/comp_calendar.html">JRX Calendar <span className="tip tip-dark p-relative bottom-2">New!</span></a></li>
                                      </ul>
                                    </div>

                                  </div>
                                </div>
                              </li>
                            </ul>

                          </li>

                          <li className="dropdown dropdown-mega">
                            <a className="dropdown-item dropdown-toggle" href="#">
                              JRX360 <i className="fas fa-arrow-down ms-2"></i>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <div className="dropdown-mega-content">

                                  <div className="row">

                                    <div className="col-lg-3">
                                      <div onclick="location.href='https://juniorx.my/jrx_news.html';">
                                        <span className="dropdown-mega-sub-title">News</span>
                                        <img className="img-fluid w-100" alt="News Logo" src={News} />
                                      </div>
                                    </div>

                                    <div className="col-lg-3">
                                      <div onclick="location.href='https://juniorx.my/jrx_stats.html';">
                                        <span className="dropdown-mega-sub-title">Stats</span>
                                        <img className="img-fluid w-100" alt="Stas Logo" src={Stats} />
                                      </div>
                                    </div>

                                    <div className="col-lg-3">
                                      <div onclick="location.href='https://juniorx.my/jrx_gallery.html';">
                                        <span className="dropdown-mega-sub-title">Gallery</span>
                                        <img className="img-fluid w-100" alt="Stas Logo" src={Stats} />
                                      </div>
                                    </div>

                                    <div className="col-lg-3">
                                      <span className="dropdown-mega-sub-title">JRX360</span>
                                      <ul className="dropdown-mega-sub-nav">
                                        <li><a className="dropdown-item" href="https://juniorx.my/jrx_news.html">News</a></li>
                                        <li><a className="dropdown-item" href="https://juniorx.my/jrx_stats.html">Stats</a></li>
                                        <li><a className="dropdown-item" href="https://juniorx.my/jrx_gallery.html">Gallery<span className="tip tip-dark p-relative bottom-2">New!</span></a></li>
                                      </ul>
                                    </div>

                                  </div>
                                </div>
                              </li>
                            </ul>

                          </li>

                          <li><a className="dropdown-item active" href="https://register.juniorx.my/">Register</a></li>

                        </ul>

                      </nav>
                    </div>
                  </div>
                  <div class="btn header-btn-collapse-nav">
                    <a
                      // data-bs-toggle="collapse"
                      // data-bs-target=".header-nav-main nav"
                      target='_blank'
                      href="https://juniorx.my"
                    >
                      <i class="fas fa-bars"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Router>
        </div>

      </div>
    </header>
  );
};

export default Header;
