import { useState, useEffect } from 'react';


const EventListFetch = (value) => {
    const [events, setData] = useState([]);
    const [isLoadEvent, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        setIsLoading(true);
        try {

            await new Promise(resolve => setTimeout(resolve, 300));
            const response = await fetch('https://agilemas.my/api/juniorx/list?search=' + value);
            const json = await response.json();
            setData(json);
            setIsLoading(false);
        } catch (error) {
            setError(error);
            // alert('There is an error!');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [value]);

    const refetch = () => {
        setIsLoading(false);
        fetchData();
    }
    return { events, isLoadEvent, error, refetch };
}

export default EventListFetch;
